<template>
  <page-container class="user-info-container">
    <van-form
        label-width="8.5em"
        input-align="right"
    >
      <div class="user-info-card-item">
        <van-field
            class="user-info-avatar"
            readonly
            label="头像"
        >
          <template #input>
            <van-image class="user-info-avatar" :src="userInfo.avatar"/>
          </template>
        </van-field>
        <van-field
            :value="userInfo.nickname"
            readonly
            label="昵称"
        />
        <van-field
            :value="formatLevelName(userInfo.level)"
            readonly
            label="等级"
        />
        <van-field
            :value="form.name"
            readonly
            name="name"
            label="真实姓名"
        />
        <van-field
            :value="form.idNum"
            :type="inputType"
            readonly
            name="idNum"
            label="身份证号"
        >
        </van-field>
        <van-field
            :value="form.mobile"
            :type="inputType"
            readonly
            name="mobile"
            label="手机号码"
        />
        <!-- <van-field
            readonly
            name="bank"
            label="收款银行"
            :value="form.bankName"
        />
        <van-field
            :value="form.bankAccountNo"
            :type="inputType"
            readonly
            name="bankAccountNo"
            label="银行卡号"
        >
        </van-field> -->
      </div>

      <div class="submit-container">
        <p class="show-container">
          <span>是否展示身份证号、手机号码、银行卡号 </span>
          <van-switch
              size="1.8em"
              active-value="text"
              inactive-value="password"
              v-model="inputType"
          />
        </p>
        <van-button
            round block type="primary" icon="edit"
            @click="$router.push('/userInfo')"
        >
          编辑个人信息
        </van-button>
      </div>
    </van-form>
  </page-container>
</template>

<script>
import { getUserInfo, saveUser } from '@/api/user';
import { getBankList } from '@/api/user';
import BankAddressSelector from '@/components/BankAddressSelector';
import { Toast } from 'vant';

export default {
  name: 'userInfo',
  components: { BankAddressSelector },
  data() {
    return {
      userInfo: {},
      bankList: [],
      showPicker: false,

      inputType: 'password',
      form: {
        name: '', // 真实姓名
        mobile: '', // 手机号码
        bank: '', // 开户行
        bankName: '',
        // subBank: '', // 开户支行
        bankProvince: '', // 银行所在省
        bankProvinceName: '',
        bankCity: '', // 银行所在市
        bankCityName: '',
        bankAccountNo: '', // 银行卡号
        idNum: '', // 身份证号
      },
      saving: false,
    };
  },
  async created() {
    await getUserInfo().then(res => {
      this.userInfo = res || {};
      this.form = [
        {},
        'name',
        'mobile',
        'bank',
        'bankName',
        'bankProvince',
        'bankProvinceName',
        'bankCity',
        'bankCityName',
        'bankAccountNo',
        'idNum'
      ].reduce((obj, key) => {
        obj[key] = this.userInfo[key] || '';
        return obj;
      });
    });
    getBankList().then(res => {
      this.bankList = res || [];
      let bankName = '';
      for (let bank of this.bankList) {
        if (bank.value === this.userInfo.bank) {
          bankName = bank.label;
          break;
        }
      }
      this.form.bankName = bankName;
    });
  },
  methods: {
    formatLevelName(level) {
      return {
        '1': '会员',
        '2': 'VIP',
        '3': '推广员',
        '4': '店铺',
      }[level];
    },
  },
};
</script>

<style lang="less" scoped>
.user-info-container {
  .user-info-card-item {
    margin: 10px;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
  }

  .user-info-avatar {
    /deep/ .van-field__label {
      display: flex;
      align-items: center;
    }

    .van-image {
      width: 40px;
      height: 40px;
    }
  }

  .show-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 32px;
    text-align: right;
    font-size: 12px;
    color: #666;

    & > span {
      margin-right: 10px;
    }
  }

  .submit-container {
    padding: 10px 20px 20px 20px;
  }

}
</style>
